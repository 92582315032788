import React, { useState } from "react";

// Adding Components
import Nav from "./Nav";
import Projects from "./Projects";
import About from "./About";
import ProjectInfo from "./ProjectInfo";

// Animation
import { AnimatePresence } from "framer-motion";

// Router
import { Switch, Route, useLocation } from "react-router-dom";

// Data
import data from "../data_ProjectList";

const Home = () => {
  // State
  const [projects, setProjects] = useState(data());
  const [currentProject, setCurrentProjects] = useState(projects[0]);

  const location = useLocation();
  // console.log(location);

  return (
    <div className="homepage">
      <div className="nav">
        <Nav />
      </div>

      <AnimatePresence>
        <Switch location={location} key={location.pathname}>
          <Route path="/" exact>
            <Projects
              projects={projects}
              setCurrentProjects={setCurrentProjects}
            />
          </Route>

          <Route path="/about">
            <About />
          </Route>

          <Route path="/projects">
            <ProjectInfo currentProject={currentProject} />
          </Route>
        </Switch>
      </AnimatePresence>
    </div>
  );
};

export default Home;
