import React from "react";

// Animations
import { motion } from "framer-motion";
import { pageAnim } from "../animation";

const IndividualProject = ({ projectNum }) => {
  const HoverAnim = {
    hover_anim: {
      // backgroundColor: "rgb(238, 255, 0)", // yellow
      backgroundColor: "#f1f1f1", // white
      color: "rgb(0, 0, 0)",
      transition: { duration: 0.0 },
    },
  };

  return (
    <div className="individual-project">
      <div className="col-1">
        <div className="hover_img">
          {/* <a> */}
          <div className="linkss">
            <h6>
              {projectNum.project_title}
              <span>
                <img src={projectNum.imageURL} alt="image" />
              </span>
            </h6>
          </div>
          {/* </a> */}
        </div>
      </div>
      <div className="col-2">
        <h6>{projectNum.media_type}</h6>
      </div>
    </div>
  );
};

export default IndividualProject;
