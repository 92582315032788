import React, { useState } from "react";
import { Link } from "react-router-dom";
import Sketch from "react-p5";

// Animations
import { motion } from "framer-motion";
import { pageAnim } from "../animation";

// Import images
import nameImg0 from "../media/r_logo/pauric-freeman.png";
import nameImg1 from "../media/r_logo/pauric-freeman-1.jpg";
import nameImg2 from "../media/r_logo/pauric-freeman-2.jpg";
import nameImg3 from "../media/r_logo/pauric-freeman-3.png";

// Import Components
import IndividualProject from "./IndividualProject";
import UpcomingShows from "./UpcomingShows";

// Import Util
import data from "../data_ProjectList";
import showsData from "../data_UpcomingShows";

const Projects = ({ projects, setCurrentProjects }) => {
  const projectSelectHandler = (num) => {
    setCurrentProjects(projects[num]);
  };

  // Animations
  const HoverAnim = {
    hover_anim: {
      // backgroundColor: "rgb(238, 255, 0)", // yellow
      backgroundColor: "#000000", // white
      color: "#e9e9e9",
      transition: { duration: 0.0 },
    },
  };

  const project_Data = data();
  const shows_Data = showsData();

  var cnv;
  var seperation = 55;
  var w = 300;
  var h = 100;
  var d = 0;

  var img0;
  var img1;
  var img2;
  var img3;
  var name0;
  var name1;
  var name2;
  var name3;
  var gl;

  var mouseXPos = 0;
  var mouseYPos = 0;
  var mouseXPos1 = 0;
  var mouseYPos1 = 0;
  var lerpSpeed = 0.01;

  const setup = (p5, parent) => {
    cnv = p5.createCanvas(600, 400, p5.WEBGL).parent(parent);
    // cnv.disable(cnv.DEPTH_TEST);
    // cnv.getContext("webgl").disable(cnv.getContext("webgl").DEPTH_TEST);

    // gl = document.getElementById("defaultCanvas0").getContext("webgl");
    // gl.enable(gl.DEPTH_TEST);

    p5.background(233, 0);

    p5.ortho(
      -p5.width / 2,
      p5.width / 2,
      p5.height / 2,
      -p5.height / 2,
      0,
      5000
    );

    p5.smooth();

    img0 = p5.loadImage(nameImg0);
    img1 = p5.loadImage(nameImg1);
    img2 = p5.loadImage(nameImg2);
    img3 = p5.loadImage(nameImg3);

    name0 = p5.createGraphics(400, 400);
    name1 = p5.createGraphics(444, 125);
    name2 = p5.createGraphics(600, 125);
    name3 = p5.createGraphics(800, 800);
    name1.background(255, 72, 0, 255);
  };

  const draw = (p5) => {
    // p5.background(233, 0);
    p5.clear();

    d = p5.dist(p5.width / 2, p5.height / 2, p5.mouseX, p5.mouseY);
    d = 255 - d * 0.3;

    p5.rotateX(3.14);

    mouseXPos = p5.lerp(mouseXPos, p5.mouseX, lerpSpeed);
    mouseYPos = p5.lerp(mouseYPos, p5.mouseY, lerpSpeed);

    // mouseXPos += 0.67;
    // mouseYPos += 0.21;
    // mouseXPos1 += 0.16;
    // mouseYPos1 += 0.29;

    p5.smooth(8);

    // // CIRCLE
    p5.fill(0);
    p5.noStroke();
    // p5.strokeWeight(2);
    // p5.ellipse(0, 0, 6, 6);

    p5.rotateX(mouseXPos * -0.01);
    p5.rotateY(mouseYPos * -0.01);

    name0.image(img0, 0, 0, 400, 400);
    name1.image(img1, 0, 0, 444, 125);
    name2.image(img2, 0, 0, 600, 125);
    name3.image(img3, 0, 0, 800, 800);

    ///////////////////////////////////////
    /////////                        SPHERE
    ///////////////////////////////////////
    // p5.texture(name3);
    // p5.sphere(120);

    ///////////////////////////////////////
    /////////                        PANELS
    ///////////////////////////////////////
    // // PAURIC
    p5.rectMode(p5.CENTER);
    p5.noStroke();
    p5.rotateX(mouseXPos * 0.0001);
    p5.rotateY(mouseYPos * -0.0001);
    p5.texture(name1);
    p5.rect(0, -seperation, 222, 62);

    // FREEMAN
    p5.rotateX(mouseYPos * -0.002);
    p5.rotateY(mouseXPos * 0.002);
    p5.texture(name2);
    p5.rect(0, seperation, 300, 62);
    p5.rotateX(1);
    p5.rotateY(1);

    // // BOX
    p5.noFill();
    p5.stroke(0);
    p5.strokeWeight(2);
    p5.box(20);
  };

  return (
    <div className="pages">
      <motion.div
        className="projects"
        variants={pageAnim}
        initial="hidden"
        exit="exit"
        animate="show"
      >
        <div className="title">
          <h1>SELECTED PROJECTS</h1>
        </div>

        <ul>
          <li>
            <Link
              to="/projects"
              className="project-link"
              onClick={() => projectSelectHandler(0)}
            >
              <IndividualProject projectNum={project_Data[0]} />
            </Link>
          </li>
          <li>
            <Link
              to="/projects"
              className="project-link"
              onClick={() => projectSelectHandler(1)}
            >
              <IndividualProject projectNum={project_Data[1]} />
            </Link>
          </li>
          <li>
            <Link
              to="/projects"
              className="project-link"
              onClick={() => projectSelectHandler(2)}
            >
              <IndividualProject projectNum={project_Data[2]} />
            </Link>
          </li>
          <li>
            <Link
              to="/projects"
              className="project-link"
              onClick={() => projectSelectHandler(3)}
            >
              <IndividualProject projectNum={project_Data[3]} />
            </Link>
          </li>
          <li>
            <Link
              to="/projects"
              className="project-link"
              onClick={() => projectSelectHandler(4)}
            >
              <IndividualProject projectNum={project_Data[4]} />
            </Link>
          </li>
          <li>
            <Link
              to="/projects"
              className="project-link"
              onClick={() => projectSelectHandler(5)}
            >
              <IndividualProject projectNum={project_Data[5]} />
            </Link>
          </li>
          <li>
            <Link
              to="/projects"
              className="project-link"
              onClick={() => projectSelectHandler(6)}
            >
              <IndividualProject projectNum={project_Data[6]} />
            </Link>
          </li>
          <li>
            <Link
              to="/projects"
              className="project-link"
              onClick={() => projectSelectHandler(7)}
            >
              <IndividualProject projectNum={project_Data[7]} />
            </Link>
          </li>
          <li>
            <Link
              to="/projects"
              className="project-link"
              onClick={() => projectSelectHandler(8)}
            >
              <IndividualProject projectNum={project_Data[8]} />
            </Link>
          </li>
          <li>
            <Link
              to="/projects"
              className="project-link"
              onClick={() => projectSelectHandler(9)}
            >
              <IndividualProject projectNum={project_Data[9]} />
            </Link>
          </li>
          <li>
            <Link
              to="/projects"
              className="project-link"
              onClick={() => projectSelectHandler(10)}
            >
              <IndividualProject projectNum={project_Data[10]} />
            </Link>
          </li>
        </ul>

        <br></br>
        <br></br>
        <br></br>
        <br></br>
        {/* 
        <div className="title">
          <h1>UPCOMING - LIVE SHOWS</h1>
        </div>

        <ul>
          <li>
            <UpcomingShows projectNum={shows_Data[0]} />
          </li>
        </ul> */}
      </motion.div>
      <motion.div
        className="interactive-area"
        variants={pageAnim}
        initial="hidden"
        exit="exit"
        animate="show"
      >
        <Sketch setup={setup} draw={draw} />
      </motion.div>
    </div>
  );
};

export default Projects;
