import { v4 as uuidv4 } from "uuid";

// Import images
import ComplexGig from "./media/r_images_centered/Complex-Gig-12.gif";
import BDsupport from "./media/r_images_centered/BD-support-2.gif";
import MotionStudy from "./media/r_images_centered/Motion-Study_animated-2.gif";
import SoundObjects from "./media/r_images_centered/SoundObjects-2.gif";
import StorytellingAcrossMedia from "./media/r_images_centered/StorytellingAcrossMedia-2.gif";
import RiverPoem from "./media/r_images_centered/RiverPoem.png";
import SynapseRelapse from "./media/r_images_centered/Synapse-Relapse.png";
import Titan from "./media/r_images_centered/Titan.jpg";
import DHxNGV from "./media/r_images_centered/DHxNGV.jpg";
import CommonGround from "./media/r_images_centered/Common-Ground.png";
import SkinLiquidState from "./media/r_images_centered/SkinLiquidState_04.gif";

// Complex Gallery Dublin
import ComplexGig1 from "./media/r_projects/complex-gig/Performance-at-Complex-Gallery-01.jpg";
import ComplexGig2 from "./media/r_projects/complex-gig/Performance-at-Complex-Gallery-02.jpg";
import ComplexGig3 from "./media/r_projects/complex-gig/Performance-at-Complex-Gallery-03.jpg";
import ComplexGig4 from "./media/r_projects/complex-gig/Performance-at-Complex-Gallery-04.jpg";

// Motion Study
import MotionStudy01 from "./media/r_projects/motion-study/MS-01.png";
import MotionStudy02 from "./media/r_projects/motion-study/MS-02.jpg";
import MotionStudy03 from "./media/r_projects/motion-study/MS-03.jpg";
import MotionStudy04 from "./media/r_projects/motion-study/MS-04.jpg";
import MotionStudy05 from "./media/r_projects/motion-study/MS-05.jpg";

// Spiral @ Lightmoves
import Spiral1 from "./media/r_projects/spiral/Spiral-01.jpg";
import Spiral2 from "./media/r_projects/spiral/Spiral-02.jpg";
import Spiral3 from "./media/r_projects/spiral/Spiral-03.jpg";
import Spiral4 from "./media/r_projects/spiral/Spiral-04.jpg";
import Spiral5 from "./media/r_projects/spiral/Spiral-05.jpg";
import Spiral6 from "./media/r_projects/spiral/Spiral-06.jpg";
import Spiral7 from "./media/r_projects/spiral/Spiral-07.jpg";
import Spiral8 from "./media/r_projects/spiral/Spiral-08.jpg";
import Spiral9 from "./media/r_projects/spiral/Spiral-09.jpg";
import Spiral10 from "./media/r_projects/spiral/Spiral-10.jpg";
import Spiral11 from "./media/r_projects/spiral/Spiral-11.jpg";

// Synapse Relapse
import SR01 from "./media/r_projects/synapse-relapse/sr-01.jpg";
import SR02 from "./media/r_projects/synapse-relapse/sr-02.jpg";
import SR03 from "./media/r_projects/synapse-relapse/sr-03.jpg";
import SR04 from "./media/r_projects/synapse-relapse/sr-04.jpg";
import SR05 from "./media/r_projects/synapse-relapse/sr-05.jpg";
import SR06 from "./media/r_projects/synapse-relapse/sr-06.jpg";
import SR07 from "./media/r_projects/synapse-relapse/sr-07.jpg";
import SR08 from "./media/r_projects/synapse-relapse/sr-08.jpg";
import SR09 from "./media/r_projects/synapse-relapse/sr-09.jpg";
import SR10 from "./media/r_projects/synapse-relapse/sr-10.jpg";
import SR11 from "./media/r_projects/synapse-relapse/sr-11.jpg";

// SAM
import SAM01 from "./media/r_projects/sam/SAM-01.jpg";
import SAM02 from "./media/r_projects/sam/SAM-02.jpg";
import SAM03 from "./media/r_projects/sam/SAM-03.jpg";
import SAM04 from "./media/r_projects/sam/SAM-04.jpg";

// Skin Liquid State
import SLS01 from "./media/r_projects/skin-liquid-state/SLS-01.jpg";
import SLS02 from "./media/r_projects/skin-liquid-state/SLS-02.jpg";
import SLS03 from "./media/r_projects/skin-liquid-state/SLS-03.jpg";
import SLS04 from "./media/r_projects/skin-liquid-state/SLS-04.jpg";
import SLS05 from "./media/r_projects/skin-liquid-state/SLS-05.jpg";

// Gasometer
import GASO01 from "./media/r_projects/gasometer/Gasometer-01.jpg";
import GASO02 from "./media/r_projects/gasometer/Gasometer-02.jpg";

function UpcomingShows_array() {
  return [
    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    //////////////////////////////// Dublin
    /////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////
    {
      show: "Spiral",
      place: "September 16th - Spiral",
      date: "Dublin Castle, Ireland",
      imageURL: ComplexGig,
      id: uuidv4(),
      section: (
        <div className="project-info">
          <div className="text-body">
            <h6>Skin Liquid State</h6>
            <p>2020</p>
            <p>Creative Code Festival, New York</p>
            <p>Audiovisual composition</p>
            <p>Video, 2 channel audio</p>
            <p>3650 x 1200</p>
            <p>4:47 duration</p>
            <p>
              Audiovisual composition, displayed at the Creative Code Festival,
              New York.
            </p>
            <img src={SLS01} alt="Skin Liquid State" />
            <img src={SLS02} alt="Skin Liquid State" />
            <img src={SLS03} alt="Skin Liquid State" />
            <img src={SLS04} alt="Skin Liquid State" />
          </div>
        </div>
      ),
    },
  ];
}

export default UpcomingShows_array;
