import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Sketch from "react-p5";

// Import images
import nameImg0 from "../media/r_logo/pauric-freeman.png";
import nameImg1 from "../media/r_logo/pauric-freeman-1.jpg";
import nameImg2 from "../media/r_logo/pauric-freeman-2.jpg";

const Nav = () => {
  const HoverAnim = {
    hover_anim: {
      // backgroundColor: "rgb(238, 255, 0)", // yellow
      backgroundColor: "#000000", // white
      color: "#e9e9e9",
      transition: { duration: 0.5 },
    },
  };

  return (
    <div className="main-nav">
      <div className="nav-top">
        <div className="nav-title">
          <h1>
            <Link to="/">
              <motion.div variants={HoverAnim} whileHover="hover_anim">
                PROJECTS
              </motion.div>
            </Link>
          </h1>
        </div>
        <div className="nav-about">
          <h1>
            <Link to="/about">
              <motion.div variants={HoverAnim} whileHover="hover_anim">
                ABOUT
              </motion.div>
            </Link>
          </h1>
        </div>
      </div>

      <div className="nav-bottom">
        <div className="nav-contact">
          <h1>
            <Link to="/about">
              <motion.div variants={HoverAnim} whileHover="hover_anim">
                CONTACT
              </motion.div>
            </Link>
          </h1>
        </div>

        <div className="nav-ig">
          <h1>
            <a href="https://www.instagram.com/pauric_freeman" target="_blank">
              <motion.div variants={HoverAnim} whileHover="hover_anim">
                IG
              </motion.div>
            </a>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Nav;
