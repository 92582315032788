import React from "react";
import { motion } from "framer-motion";

// Import Styles
import "./styles/app.scss";

// Adding Components
import Home from "./components/Home";

let mouseCursor = document.querySelector(".mycursor");
let mouseTarget = document.querySelector("body");
// let frm = document.querySelectorAll("iframe");
let navLinks = document.querySelectorAll("a");
let regLinks = document.querySelectorAll("h1");

window.addEventListener("mousemove", cursor);
window.addEventListener("mouseleave", cursor);
// frm.contentWindow.addEventListener("mousemove", cursor);

function cursor(e) {
  mouseCursor.style.top = e.pageY + "px";
  mouseCursor.style.left = e.pageX + "px";

  mouseTarget.addEventListener("mouseleave", (e) => {
    mouseCursor.style.top = -30 + "px";
    mouseCursor.style.left = -30 + "px";
  });
}

navLinks.forEach((link) => {
  link.addEventListener("mouseover", () => {
    // console.log("hello");
    mouseCursor.classList.add("link-grow");
  });

  link.addEventListener("mouseleave", () => {
    // console.log("bye");
    mouseCursor.classList.remove("link-grow");
  });
});

regLinks.forEach((link) => {
  link.addEventListener("mouseover", () => {
    // console.log("hello");
    mouseCursor.classList.add("link-grow");
  });

  link.addEventListener("mouseleave", () => {
    // console.log("bye");
    mouseCursor.classList.remove("link-grow");
  });
});

function App() {
  return (
    <motion.div
      className="App"
      animate={{
        opacity: 1,
        transition: { duration: 0 },
      }}
      initial={{
        opacity: 0,
      }}
    >
      <Home />
    </motion.div>
  );
}

export default App;
