import React from "react";

// Animations
import { motion } from "framer-motion";
import { pageAnim } from "../animation";

// Router
import { Switch, Route, useLocation } from "react-router-dom";

const ProjectInfo = ({ currentProject }) => {
  const HoverAnim = {
    hover_anim: {
      // backgroundColor: "rgb(238, 255, 0)", // yellow
      backgroundColor: "#000000", // white
      color: "#e9e9e9",
      transition: { duration: 0.0 },
    },
  };

  return (
    // <motion.div
    //   className="pages-project-info-1"
    //   variants={pageAnim}
    //   initial="hidden"
    //   exit="exit"
    //   animate="show"
    // >
    <motion.div
      className="pages-project-info"
      variants={pageAnim}
      initial="hidden"
      exit="exit"
      animate="show"
    >
      {currentProject.section}
    </motion.div>
    // </motion.div>
  );
};

export default ProjectInfo;
